import Cookies from 'js-cookie';

// Bloomreach recommends to generate unique, random values of 13 digits.
const getRequestId = (): number => {
  const min = 1000000000000;
  const max = 9999999999999;
  const requestId = Math.floor(Math.random() * (max - min + 1)) + min;

  return requestId;
};

const SearchReducerInitialState = {
  fl: 'pid,phx_id,title,brand,type,price,thumb_image,url,short_description,description,desc_under_image,path,no_of_reviews,review_rating,skuid',
  search_type: 'keyword',
  start: 0,
  rows: 10,
  account_id: (process.env.REACT_APP_ACCOUNT_ID ?? ''),
  domain_key: (process.env.REACT_APP_DOMAIN_KEY ?? ''),
  q: 'disney',
  request_type: 'search',
  url: document.location.href,
  view_id: (process.env.REACT_APP_SEARCH_VIEW_ID ?? ''),
  fq: '',
  _br_uid_2: Cookies.get('_br_uid_2') ?? '',
  request_id: getRequestId()
};

export default SearchReducerInitialState;
