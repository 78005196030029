export const addToDataLayer = (data: object): void => {
  if (
    typeof window !== 'undefined' &&
    typeof window.dataLayer !== 'undefined'
  ) {
    window.dataLayer.push({
      event: 'search',
      data
    });
  }
};
