import { type IFacetAction, type IFacetContextValue } from '../../types/facetTypes';
import FacetActionTypes from './FacetActionTypes';
import FacetReducerInitialState from './FacetReducerInitialState';

export const FacetReducer = (state = FacetReducerInitialState, action: IFacetAction): IFacetContextValue => {
  const { type, payload } = action;

  switch (type) {
    case FacetActionTypes.ADD_CATEGORY_1_FACET_VALUE: {
      // Remove empty values.
      const currentState = state.category_1.filter((str) => str !== '');
      return {
        ...state,
        category_1: [...currentState, payload]
      };
    }
    case FacetActionTypes.REMOVE_CATEGORY_1_FACET_VALUE: {
      const category1Items = state.category_1.filter((item) => { return item !== payload; });
      return {
        ...state,
        category_1: category1Items
      };
    }

    case FacetActionTypes.ADD_CATEGORY_3_FACET_VALUE: {
      // Remove empty values.
      const currentState = state.category_3.filter((str) => str !== '');
      return {
        ...state,
        category_3: [...currentState, payload]
      };
    }
    case FacetActionTypes.REMOVE_CATEGORY_3_FACET_VALUE: {
      const category3Items = state.category_3.filter((item) => { return item !== payload; });
      return {
        ...state,
        category_3: category3Items
      };
    }

    default:
      return state;
  }
};

export default FacetReducer;
