import React, {
  useMemo,
  useReducer
} from 'react';
import { FacetContext } from '../../contexts/FacetContext';
import { FacetReducer } from '../../reducers/FacetReducer';
import FacetReducerInitialState from '../../reducers/FacetReducer/FacetReducerInitialState';
import { type IChildrenProps } from '../../types/commonTypes';

export const FacetProvider: React.FC<IChildrenProps> = ({ children }) => {
  // Store the current facet values in state.
  const [facetValues, setFacetValues] = useReducer(
    FacetReducer,
    FacetReducerInitialState
  );

  // You should use useMemo to memoize the values returned to the Context Provider.
  // It reduces context consumers from re-rendering if no changes occur.
  const FacetContextValue = useMemo(() => ({
    facetValues,
    setFacetValues
  }), [facetValues]);

  return (
    <FacetContext.Provider value={FacetContextValue}>{children}</FacetContext.Provider>
  );
};

export default FacetProvider;
