const AppConfigReducerInitialState = {
  search_results_loaded: false,
  product_search_api_base_url: (process.env.REACT_APP_PRODUCT_SEARCH_API_BASE_URL ?? ''),
  api_base_url: process.env.REACT_APP_API_BASE_URL ?? `//${window.location.hostname}/api/`,
  current_language: (process.env.REACT_APP_DEFAULT_LANG ?? ''),
  mobile_facet_open: false,
  search_page_url: (process.env.REACT_APP_SEARCH_PAGE_URL ?? '')
};

export default AppConfigReducerInitialState;
