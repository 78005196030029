import { type IAction } from '../../types/commonTypes';
import { type ISearchContextValue } from '../../types/searchResultsType';
import SearchActionTypes from './SearchActionTypes';
import SearchReducerInitialState from './SearchReducerInitialState';

export const SearchReducer = (state = SearchReducerInitialState, action: IAction): ISearchContextValue => {
  switch (action.type) {
    case SearchActionTypes.SET_ROWS:
      return {
        ...state,
        rows: Number(action.payload)
      };
    case SearchActionTypes.SET_VIEW:
      return {
        ...state,
        view_id: action.payload.toString()
      };
    case SearchActionTypes.SET_START:
      return {
        ...state,
        start: Number(action.payload)
      };
    case SearchActionTypes.SET_FACETS:
      return {
        ...state,
        fq: action.payload.toString()
      };
    case SearchActionTypes.SET_SEARCH_TERM:
      return {
        ...state,
        q: action.payload.toString()
      };
    case SearchActionTypes.RESET_FILTERS:
      return SearchReducerInitialState;
    default:
      return state;
  }
};

export default SearchReducer;
