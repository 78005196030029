const SearchActionTypes = {
  SET_ROWS: 'SET_ROWS',
  SET_VIEW: 'SET_VIEW',
  SET_START: 'SET_START',
  SET_FACETS: 'SET_FACETS',
  SET_SEARCH_TERM: 'SET_SEARCH_TERM',
  RESET_FILTERS: 'RESET_FILTERS'
};

export default SearchActionTypes;
