import React, {
  useMemo,
  useEffect,
  useReducer
} from 'react';
// Default currency $.
import numeral from 'numeral';
// Add £.
import 'numeral/locales/en-gb';
// Add €.
import '../../locales/numeral/en-ie';
// Add A$.
import '../../locales/numeral/en-au';
import { node } from 'prop-types';
import { useAppConfigContext } from '../../hooks/useAppConfigContext';
import { SearchContext } from '../../contexts/SearchContext';
import { SearchReducer } from '../../reducers/SearchReducer';
import { isEmpty } from '../../utils/utilities';
import SearchActionTypes from '../../reducers/SearchReducer/SearchActionTypes';
import { getSearchParams, getSearchTermFromUrl } from '../../utils/searchQuery';
import { useFacetContext } from '../../hooks/useFacetContext';
import { formatFacetValues } from '../../utils/facets';
import { type IChildrenProps } from '../../types/commonTypes';
import { addToDataLayer } from '../../utils/dataLayer';

export const SearchProvider: React.FC<IChildrenProps> = ({ children }) => {
  // Get the site config from one of the expected locations
  // and configure the product search. For Drupal websites, this
  // will be the Drupal settings.
  const initialSearchParams = getSearchParams();

  // Get the app config state.
  const {
    appConfig
  } = useAppConfigContext();

  // Get the facet state.
  const {
    facetValues
  } = useFacetContext();

  // Store the current product search parameters in state.
  const [searchParams, setSearchParams] = useReducer(
    SearchReducer,
    initialSearchParams
  );

  // When the lang changes (initial selection), we need to update the related states.
  useEffect(() => {
    // Update the currency formatting.
    let numeralLocale = '';
    if (appConfig.current_language === 'en') {
      numeralLocale = 'en-gb';
    } else if (appConfig.current_language === 'de-de' ||
      appConfig.current_language === 'en-ie' ||
      appConfig.current_language === 'en-nl') {
      numeralLocale = 'en-ie';
    } else if (appConfig.current_language === 'en-au') {
      numeralLocale = 'en-au';
    }
    if (numeralLocale.length > 0) {
      numeral.locale(numeralLocale);
    } else {
      numeral.reset();
    }
  }, [appConfig.current_language]);

  // Set facets from facet context.
  useEffect(() => {
    if (!isEmpty(facetValues)) {
      setSearchParams({
        type: SearchActionTypes.SET_FACETS,
        payload: formatFacetValues(facetValues)
      });
      // Send to data Layer.
      addToDataLayer({ filters: facetValues });
    }
  }, [facetValues]);

  // Set search term from url if present else default.
  useEffect(() => {
    let searchTerm = searchParams.q;

    if (!isEmpty(document.location.search)) {
      const queryTerm = getSearchTermFromUrl(document.location.search);
      searchTerm = !isEmpty(queryTerm) ? queryTerm : searchTerm;
    }

    setSearchParams({
      type: SearchActionTypes.SET_SEARCH_TERM,
      payload: searchTerm
    });

    // Send to data Layer.
    addToDataLayer({ term: searchTerm });
  }, []);

  // You should use useMemo to memoize the values returned to the Context Provider.
  // It reduces context consumers from re-rendering if no changes occur.
  const SearchContextValue = useMemo(() => ({
    searchParams,
    setSearchParams
  }), [searchParams]);

  return (
    <SearchContext.Provider value={SearchContextValue}>{children}</SearchContext.Provider>
  );
};

SearchProvider.propTypes = {
  children: node.isRequired
};

SearchProvider.defaultProps = {};

export default SearchProvider;
