import React, { Suspense } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { AppConfigProvider } from './providers/AppConfigProvider';
import { SearchProvider } from './providers/SearchProvider';
import { Spinner } from './components/Spinner';
import { FacetProvider } from './providers/FacetProvider';

// Lazy load the app whilst the display filters are being set up.
const LazyLayout = React.lazy(async () => await import('./components/Layout').then(({ Layout }) => ({ default: Layout })));

// Initialise React Query Client.
const queryClient = new QueryClient();

const App: React.FC = () => (
  <QueryClientProvider client={queryClient}>
    <Suspense fallback={<Spinner />}>
      <AppConfigProvider>
        <FacetProvider>
          <SearchProvider>
            <LazyLayout />
          </SearchProvider>
        </FacetProvider>
      </AppConfigProvider>
    </Suspense>
    {process.env.NODE_ENV === 'development' && <ReactQueryDevtools initialIsOpen={false} />}
  </QueryClientProvider>
);

export default App;
