/** Process search results to get useful information. */
import { isEmpty } from './utilities';
import FacetActionTypes from '../reducers/FacetReducer/FacetActionTypes';
import { type ISearchResponse } from '../types/searchResultsType';
import { type ISearchFacetItem, type IFacetContextValue } from '../types/facetTypes';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const getFacetsList = (results: ISearchResponse, key: keyof IFacetContextValue) => {
  let data: ISearchFacetItem[] = [];
  if (
    !isEmpty(results) &&
    !isEmpty(results.facet_counts) &&
    !isEmpty(results.facet_counts.facet_fields) &&
    !isEmpty(results.facet_counts.facet_fields[key])
  ) {
    data = results.facet_counts.facet_fields[key];
  }

  return data;
};

/** Get action type for given facet category field. */
export const getFacetActionType = (field: string, action: string): string => {
  if (isEmpty(field)) {
    return '';
  }

  if (field === 'category_1') {
    if (action === 'add') {
      return FacetActionTypes.ADD_CATEGORY_1_FACET_VALUE;
    }
    return FacetActionTypes.REMOVE_CATEGORY_1_FACET_VALUE;
  }

  if (field === 'category_3') {
    if (action === 'add') {
      return FacetActionTypes.ADD_CATEGORY_3_FACET_VALUE;
    }
    return FacetActionTypes.REMOVE_CATEGORY_3_FACET_VALUE;
  }

  return '';
};

/** Process facet values to get facet string for search. */
export const formatFacetValues = (facetValues: IFacetContextValue): string => {
  let facetString = '';

  if (isEmpty(facetValues)) {
    return '';
  }

  Object.entries(facetValues).forEach(([field, facet]): void => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    if (!isEmpty(facet)) {
      facetString += 'fq=' + field.toString() + ':';
      let firstValue = true;
      // @todo: Check and fix this.
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      facet.forEach((value: string) => {
        if (!isEmpty(value)) {
          if (!firstValue) {
            facetString += ' OR ';
          }
          facetString += '"' + value + '"';
          firstValue = false;
        }
      });
      facetString += '&';
    }
  });

  return facetString;
};

/** Check if the given facet value is checked or not. */
export const isFacetValueSelected = (facetValues: IFacetContextValue, field: string, name: string): boolean => {
  let isSelected = false;

  if (!isEmpty(facetValues[field as keyof IFacetContextValue])) {
    isSelected = facetValues[field as keyof IFacetContextValue].includes(name);
  }
  return isSelected;
};

/** Cleanup selected facet list every time a new search is performed. */
export const cleanupSelectedFacets = (facetsList: ISearchFacetItem[], selectedFacets: string[]): string => {
  let facetToUnselect = '';

  if (!isEmpty(selectedFacets) || !isEmpty(facetsList)) {
    facetToUnselect = selectedFacets.filter(facet => !facetsList.map(data => data.name).includes(facet)).toString();
  }

  return facetToUnselect;
};
