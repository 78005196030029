import AppConfigActionTypes from './AppConfigActionTypes';
import AppConfigReducerInitialState from './AppConfigReducerInitialState';
import { type IAppContextValue } from '../../types/appConfigTypes';
import { type IAction } from '../../types/commonTypes';

export const AppConfigReducer = (state = AppConfigReducerInitialState, action: IAction): IAppContextValue => {
  switch (action.type) {
    case AppConfigActionTypes.SET_SEARCH_RESULTS_LOADED:
      return {
        ...state,
        search_results_loaded: Boolean(action.payload)
      };

    case AppConfigActionTypes.SET_CURRENT_LANGUAGE:
      return {
        ...state,
        current_language: action.payload.toString()
      };

    case AppConfigActionTypes.OPEN_MOBILE_FACET:
      return {
        ...state,
        mobile_facet_open: Boolean(action.payload)
      };

    default:
      return state;
  }
};

export default AppConfigReducer;
