/* eslint-disable no-undef */
/** Get the search params. */
import SearchReducerInitialState from '../reducers/SearchReducer/SearchReducerInitialState';
import { type ISearchContextValue } from '../types/searchResultsType';
import { isEmpty } from './utilities';

export const getSearchParams = (): ISearchContextValue => {
  const searchParams = SearchReducerInitialState;

  if (
    typeof window !== 'undefined' &&
    typeof window.drupalSettings !== 'undefined' &&
    !isEmpty(window.drupalSettings.search_results_app)
  ) {
    const { account_id: accountId, domain_key: domainKey, view_id: viewId } = window.drupalSettings.search_results_app;
    searchParams.account_id = accountId;
    searchParams.domain_key = domainKey;
    searchParams.view_id = viewId;
  }

  return searchParams;
};

/**
 * A function format the search object into a query string.
 *
 * @param {*} search
 */
export const getSearchQueryString = (search: ISearchContextValue): string => Object.keys(search)
  .filter((key) => (typeof search[key as keyof ISearchContextValue] !== 'undefined' && search[key as keyof ISearchContextValue].toString().length > 0)).map((key) => {
    let searchQuery = '';
    searchQuery = (key === 'fq') ? `${search[key]}` : `${key}=${search[key as keyof ISearchContextValue]}`;
    return searchQuery;
  })
  .join('&');

export default getSearchQueryString;

/** Get the search text from url query param. */
export const getSearchTermFromUrl = (searchPath: string): string => {
  let term = '';
  const searchParams = new URLSearchParams(searchPath);

  if (typeof searchParams !== 'undefined') {
    term = searchParams.get('q') ?? '';
  }

  return term;
};
